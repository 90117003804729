import React from 'react';

function CookiePolicyPage() {
  return (
    <div className="container mx-auto px-4 py-8 md:ml-64">
      <h1 className="text-2xl font-bold text-center mb-6">Política de Cookies de PetitPrime</h1>
      <p>En PetitPrime, accesible desde https://petitprime.com, una de nuestras principales prioridades es la privacidad de nuestros visitantes. Esta Política de Cookies explica qué son las cookies y cómo las usamos.</p>
      <h2 className="text-xl font-semibold mt-4">¿Qué son las cookies?</h2>
      <p>Las cookies son pequeños archivos de texto que se usan para almacenar pequeños fragmentos de información...</p>

      <h2 className="text-xl font-semibold mt-4">¿Cómo usamos las cookies?</h2>
      <p>Como la mayoría de los servicios en línea, nuestro sitio web utiliza cookies propias y de terceros por varias razones...</p>

      <h2 className="text-xl font-semibold mt-4">¿Qué tipos de cookies utilizamos?</h2>
      <ul>
        <li><strong>Esenciales:</strong> Algunas cookies son esenciales para que puedas experimentar la funcionalidad completa de nuestro sitio.</li>
        <li><strong>Estadísticas:</strong> Estas cookies almacenan información como el número de visitantes del sitio web...</li>
        <li><strong>Marketing:</strong> Nuestro sitio web muestra anuncios. Estas cookies se utilizan para personalizar los anuncios...</li>
      </ul>

      <h2 className="text-xl font-semibold mt-4">¿Cómo puedo controlar las preferencias de las cookies?</h2>
      <p>Puedes administrar tus preferencias de cookies ajustando las configuraciones de tu navegador...</p>

      <h2 className="text-xl font-semibold mt-4">Más información</h2>
      <p>Si tienes preguntas sobre esta política de cookies o necesitas más información, puedes contactar con nosotros:</p>
      <ul>
        <li>Email: info@petitstores.com</li>
      </ul>
    </div>
  );
}

export default CookiePolicyPage;
