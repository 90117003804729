// src/components/PublicRoute.jsx
import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import useAuthStore from '../../store/authStore';

function PublicRoute() {
  const { user, trabajador } = useAuthStore(state => ({
    user: state.user,
    trabajador: state.trabajador,
  }));

  if (user) {
    return <Navigate to="/dashboard" />;
  } else if (trabajador) {
    return <Navigate to={`/trabajador/${trabajador.trabajadorId}/dashboard`} />;
  }

  return <Outlet />;
}

export default PublicRoute;
