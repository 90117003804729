import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { get } from '../../helpers/apiHelper';
import useAuthStore from '../../store/authStore';

function SubscriptionPage() {
  const { user } = useAuthStore();
  const [userDetails, setUserDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const response = await get(`users/${user.userId}`);
        setUserDetails(response);
      } catch (error) {
        Swal.fire('Error', 'No se pudo obtener la información del usuario. Por favor, inténtelo de nuevo.', 'error');
      } finally {
        setLoading(false);
      }
    };

    fetchUserDetails();
  }, [user.userId]);

  const handleSubscription = async (type) => {
    try {
      const response = await get(`payments/start-${type}-payment/${user.userId}`);
      if (response.paymentUrl) {
        window.location.href = response.paymentUrl;
      }
    } catch (error) {
      Swal.fire('Error', 'No se pudo iniciar el proceso de suscripción. Por favor, inténtelo de nuevo.', 'error');
    }
  };

  const renderPricing = () => {
    const countryConfig = userDetails && {
      espana: {
        monthly: { amount: 30, currency: '€' },
        annual: { amount: 300, currency: '€' },
      },
      peru: {
        monthly: { amount: 40, currency: 'PEN' },
        annual: { amount: 400, currency: 'PEN' },
      },
      chile: {
        monthly: { amount: 7500, currency: 'CLP' },
        annual: { amount: 75000, currency: 'CLP' },
      },
    };

    const config = (userDetails && countryConfig[userDetails.pais.toLowerCase()]) || countryConfig['espana'];
    return (
      <>
        <div className="bg-white text-black rounded-lg shadow-lg p-6 border-4 border-blue-500">
          <h3 className="text-2xl font-bold mb-4">Suscripción Mensual</h3>
          <p className="text-lg mb-4">{config.monthly.amount} {config.monthly.currency} al mes</p>
          <ul className="list-disc list-inside mb-4">
            <li>Acceso a todas las funciones</li>
            <li>Cancela en cualquier momento</li>
          </ul>
          <button
            onClick={() => handleSubscription('monthly')}
            className="w-full py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-yellow-500 hover:bg-yellow-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500"
          >
            Suscribirse Mensualmente
          </button>
        </div>
        <div className="bg-white text-black rounded-lg shadow-lg p-6 border-4 border-green-500">
          <h3 className="text-2xl font-bold mb-4">Suscripción Anual</h3>
          <p className="text-lg mb-4">{config.annual.amount} {config.annual.currency} al año</p>
          <p className="text-green-500 font-bold mb-4">¡Ahorra {config.monthly.amount * 2} {config.annual.currency} comparado con la suscripción mensual!</p>
          <ul className="list-disc list-inside mb-4">
            <li>Acceso a todas las funciones</li>
            <li>Cancela en cualquier momento</li>
          </ul>
          <button
            onClick={() => handleSubscription('annual')}
            className="w-full py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-green-500 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
          >
            Suscribirse Anualmente
          </button>
        </div>
      </>
    );
  };

  if (loading) {
    return <div className="flex justify-center items-center h-screen"><div className="text-xl">Cargando...</div></div>;
  }

  if (!userDetails) {
    return <div className="flex justify-center items-center h-screen"><div className="text-xl">No se pudo obtener la información del usuario</div></div>;
  }

  return (
    <div className="min-h-screen flex flex-col items-center justify-center text-black py-12 px-4 sm:px-6 lg:px-8 md:ml-64">
      <div className="max-w-4xl w-full space-y-8">
        <h2 className="mt-6 text-center text-3xl sm:text-4xl font-extrabold">Elige tu Suscripción</h2>
        <div className="mt-8 grid grid-cols-1 md:grid-cols-2 gap-8">
          {renderPricing()}
        </div>
        <button
          onClick={() => navigate('/')}
          className="w-full py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-blue-500 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 mt-8"
        >
          Volver
        </button>
      </div>
    </div>
  );
}

export default SubscriptionPage;
