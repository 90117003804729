// src/pages/TrabajadorLogin.jsx
import React, { useState } from 'react';
import useAuthStore from '../../store/authStore';
import { post } from '../../helpers/apiHelper';
import Swal from 'sweetalert2';
import { Link, useNavigate } from 'react-router-dom';

function TrabajadorLogin() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const { setTrabajador, logout } = useAuthStore(state => ({
    setTrabajador: state.setTrabajador,
    logout: state.logout
  }));
  const navigate = useNavigate();

  const handleLogin = async (event) => {
    event.preventDefault();
    try {
      const response = await post('trabajadores-auth/login', { email, password });
      if (response.token) {
        setTrabajador({ trabajadorId: response.trabajadorId, token: response.token });
        Swal.fire('Success', 'Login succesful!', 'success');
        navigate(`/trabajador/${response.trabajadorId}/dashboard`);
      } else {
        throw new Error('Invalid login response');
      }
    } catch (error) {
      console.error('Login failed:', error);
      Swal.fire('Error', 'Login failed: ' + error.message, 'error');
      logout();
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center text-black py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full space-y-8">
        <div>
          <h2 className="mt-6 text-center text-3xl font-extrabold">
            Inicia sesión como trabajador
          </h2>
        </div>
        <form className="mt-4 space-y-6" onSubmit={handleLogin}>
          <input type="hidden" name="remember" value="true" />
          <div className="rounded-md shadow-sm">
            <div>
              <label htmlFor="email-address" className="sr-only">Correo electrónico</label>
              <input id="email-address" name="email" type="email" autoComplete="email" required className="appearance-none rounded-none relative block w-full px-3 py-2 border border-collapse placeholder-black text-gray-900 rounded-t-md focus:outline-none focus:ring-white focus:border-white focus:z-10 sm:text-sm" placeholder="Introduce tu correo" value={email} onChange={(e) => setEmail(e.target.value)} />
            </div>
            <div>
              <label htmlFor="password" className="sr-only">Contraseña</label>
              <input id="password" name="password" type="password" autoComplete="current-password" required className="appearance-none rounded-none relative block w-full px-3 py-2 border border-collapse placeholder-black text-gray-900 rounded-b-md focus:outline-none focus:ring-white focus:border-white focus:z-10 sm:text-sm" placeholder="Introduce tu contraseña" value={password} onChange={(e) => setPassword(e.target.value)} />
            </div>
          </div>
          <div>
            <button type="submit" className="group relative w-full flex justify-center py-2 px-4 mt-4 border border-transparent text-sm font-medium rounded-md text-white bg-blue-400 hover:bg-blue-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
              Iniciar sesión
            </button>
          </div>
        </form>
        <div className="mt-4 text-center">
          <Link to="/login" className="font-medium text-blue-600 hover:text-blue-800">
            Volver al inicio de sesión como usuario
          </Link>
        </div>
      </div>
    </div>
  );
}

export default TrabajadorLogin;
