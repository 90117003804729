import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faChartLine, faHeadset } from '@fortawesome/free-solid-svg-icons';


function Home() {

    // Estados para manejar la selección de país y tipo de suscripción
    const [country, setCountry] = useState('espana');
  
    // Configuración de precios extraída para simplicidad en el ejemplo
    const priceConfig = {
      espana: {
        monthly: { amount: 3000, currency: 'EUR' },
        annual: { amount: 30000, currency: 'EUR' },
      },
      peru: {
        monthly: { amount: 12000, currency: 'PEN' },
        annual: { amount: 120000, currency: 'PEN' },
      },
      chile: {
        monthly: { amount: 300000, currency: 'CLP' },
        annual: { amount: 3000000, currency: 'CLP' },
      },
    };
  
    // Función para mostrar los precios según el país y el tipo de suscripción seleccionados
    const displayPrices = (country) => {
      const { monthly, annual } = priceConfig[country];
      const monthlyPrice = monthly.amount / 100;
      const annualPrice = annual.amount / 100;
      const annualSavings = ((monthlyPrice * 12) - annualPrice).toFixed(2);
      return {
        monthlyPrice: `${monthlyPrice.toFixed(2)} ${monthly.currency}`,
        annualPrice: `${annualPrice.toFixed(2)} ${annual.currency}`,
        annualSavings: `Ahorra ${annualSavings} ${annual.currency} al año`
      };
    };
  
    const prices = displayPrices(country);

  return (
    <div className="min-h-screen bg-white text-blue-600">
      <Helmet>
        <title>Página de Inicio - PetitPrime</title>
        <meta name="description" content="Simplifica la creación de programas de fidelización con PetitPrime. Nuestra herramienta está diseñada para pequeños y medianos comercios, ayudándote a entender mejor a tus clientes a través de programas de fidelización efectivos." />
      </Helmet>
      
      <header className="bg-gradient-to-r from-blue-500 via-blue-300 to-blue-400 text-white text-center py-20">
        <h1 className="text-5xl font-extrabold">Simplifica la Creación de Programas de Fidelización con PetitPrime</h1>
        <p className="text-xl mt-4">Tu aliado estratégico en programas de fidelización, diseñado para pequeños y medianos comercios.</p>
        <p className="text-xl mt-4">30 días gratis y sin introducir tarjeta de crédito.</p>
        <div className="mt-6 space-y-4 md:space-x-4 md:space-y-0 flex flex-col md:flex-row justify-center items-center">
          <Link to="/register" className="bg-white hover:bg-gray-200 text-blue-600 font-bold py-3 px-6 rounded-full text-lg shadow-lg transition-all duration-300 ease-in-out">
            Registrate gratis
          </Link>
          <Link to="https://calendly.com/d/cpp2-rhr-c8q/reunion-de-30-minutos-petitprime" className="bg-blue-600 hover:bg-blue-800 text-white font-bold py-3 px-6 rounded-full text-lg shadow-lg transition-all duration-300 ease-in-out">
            Solicita una reunion para dudas
          </Link>
        </div>
      </header>

      <section className="text-center py-16 bg-gray-100">
        <h2 className="text-3xl font-bold mb-6 text-black">Características Clave</h2>
        <div className="flex flex-col md:flex-row justify-center items-stretch">
          <div className="bg-white text-blue-600 p-8 m-4 rounded-lg shadow-lg flex flex-col items-center">
            <FontAwesomeIcon icon={faUser} className="text-4xl mb-4" />
            <h3 className="text-2xl font-semibold mb-4">Fácil de Usar</h3>
            <p>Interfaz intuitiva que permite a los usuarios gestionar programas de fidelización sin complicaciones.</p>
          </div>
          <div className="bg-white text-blue-600 p-8 m-4 rounded-lg shadow-lg flex flex-col items-center">
            <FontAwesomeIcon icon={faChartLine} className="text-4xl mb-4" />
            <h3 className="text-2xl font-semibold mb-4">Análisis en Tiempo Real</h3>
            <p>Obtén datos valiosos sobre el comportamiento de tus clientes y ajusta tus estrategias en consecuencia.</p>
          </div>
          <div className="bg-white text-blue-600 p-8 m-4 rounded-lg shadow-lg flex flex-col items-center">
            <FontAwesomeIcon icon={faHeadset} className="text-4xl mb-4" />
            <h3 className="text-2xl font-semibold mb-4">Soporte Personalizado</h3>
            <p>Asistencia dedicada para ayudarte a sacar el máximo provecho de nuestra plataforma.</p>
          </div>
        </div>
      </section>

      <section className="text-center py-16">
        <h2 className="text-3xl font-bold mb-6 text-black">Nuestros Clientes</h2>
        <div div className="flex justify-center">
          <img src="logos/youtoo.png" alt="Logo Youtoo" className="max-h-12 mx-auto" />
        </div>
      </section>

      <section className="text-center py-16 bg-gray-100 text-black">
        <h2 className="text-3xl font-bold mb-6">Demostraciones Visuales</h2>
        <div className="flex justify-center">
          <iframe
            width="560"
            height="315"
            src="https://www.youtube.com/embed/sGHYOPPSMlY"
            title="Demostración de PetitPrime"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
      </section>

      <section className="text-center py-16 text-black">
        <h2 className="text-3xl font-bold mb-6">Prueba Gratis</h2>
        <p className="text-lg mb-4">Regístrate ahora y obtén una prueba gratuita de 30 días.</p>
        <Link to="/register" className="bg-blue-600 hover:bg-blue-800 text-white font-bold py-3 px-6 rounded-full text-lg shadow-lg transition-all duration-300 ease-in-out">
          Empieza Ahora
        </Link>
      </section>

      <section className="text-center py-16 bg-gray-100 text-black">
        <h2 className="text-3xl font-bold mb-6">Precios</h2>
        <div className="max-w-md mx-auto p-6 bg-white rounded-lg shadow-lg">
          <div className="flex flex-col">
            <div className="mb-4">
              <label htmlFor="country" className="block text-sm font-bold mb-2">Selecciona tu país:</label>
              <select id="country" value={country} onChange={(e) => setCountry(e.target.value)} className="form-select block w-full px-4 py-2 text-base font-normal text-gray-700 bg-white bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none">
                <option value="espana">España</option>
                <option value="peru">Perú</option>
                <option value="chile">Chile</option>
              </select>
            </div>
            <p className="text-xl font-bold">Mensual: {prices.monthlyPrice}</p>
            <p className="text-xl font-bold">Anual: {prices.annualPrice}</p>
            <p className="text-lg mt-2 text-green-600">{prices.annualSavings}</p>
          </div>
        </div>
      </section>

      <section className="text-center py-16 text-black">
        <h2 className="text-3xl font-bold mb-6">Información de Contacto</h2>
        <p className="text-lg">¿Tienes alguna pregunta? Contáctanos:</p>
        <p className="text-lg mt-2">Email: info@petitstores.com</p>
      </section>
    </div>
  );
}

export default Home;
