// src/components/Footer.jsx
import React from 'react';
import { Link } from 'react-router-dom';

function Footer() {
  return (
    <footer className="bg-gradient-to-r from-blue-400 to-indigo-500 text-white p-4 text-center">
      <div className="flex justify-center space-x-4 mb-2">
        <Link to="/terminos" className="hover:underline">Términos y Condiciones</Link>
        <Link to="/politica-de-cookies" className="hover:underline">Política de Cookies</Link>
      </div>
      <p>Copyright © {new Date().getFullYear()} PetitPrime. Todos los derechos reservados.</p>
    </footer>
  );
}

export default Footer;
