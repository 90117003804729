import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { get } from '../../helpers/apiHelper';
import Swal from 'sweetalert2';

const renderStars = (currentPoints, totalPoints) => {
  const stars = [];
  for (let i = 0; i < totalPoints; i++) {
    if (i < currentPoints) {
      stars.push(<span key={i} className="text-yellow-500">&#9733;</span>); // Filled star
    } else {
      stars.push(<span key={i} className="text-gray-400">&#9733;</span>); // Empty star
    }
  }
  return stars;
};

const TicketView = () => {
  const { ticketUrl } = useParams();
  const [ticket, setTicket] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchTicket = async () => {
      try {
        const response = await get(`tickets/url/${ticketUrl}`);
        setTicket(response);
      } catch (error) {
        Swal.fire({
          title: 'Error',
          text: error.message,
          icon: 'error',
          confirmButtonText: 'Aceptar'
        });
      } finally {
        setLoading(false);
      }
    };

    fetchTicket();
  }, [ticketUrl]);

  if (loading) {
    return <div className="flex justify-center items-center h-screen"><div className="text-xl">Cargando...</div></div>;
  }

  if (!ticket) {
    return <div className="flex justify-center items-center h-screen"><div className="text-xl">Ticket no encontrado</div></div>;
  }

  const pointsRemaining = ticket.marca.puntosTicket - ticket.puntos;

  return (
    <div className="container mx-auto my-8 p-6 bg-white shadow-lg rounded-lg">
      <div className="text-center mb-8">
        <h1 className="text-4xl font-bold mb-2">Tarjeta de Fidelización</h1>
        <p className="text-lg text-gray-600">Detalles de la tarjeta</p>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <div className="bg-gray-100 p-4 rounded-lg shadow-md">
          <h2 className="text-2xl font-semibold mb-4">Cliente</h2>
          <p><strong>Nombre:</strong> {ticket.cliente?.nombre}</p>
          <p><strong>Apellido:</strong> {ticket.cliente?.apellido}</p>
          <p><strong>Email:</strong> {ticket.cliente?.email}</p>
        </div>
        <div className="bg-gray-100 p-4 rounded-lg shadow-md">
          <h2 className="text-2xl font-semibold mb-4">Tienda</h2>
          <p><strong>Nombre:</strong> {ticket.tienda?.nombre}</p>
          <p><strong>Ubicación:</strong> {ticket.tienda?.via} {ticket.tienda?.calle} {ticket.tienda?.numero}, {ticket.tienda?.poblacion}, {ticket.tienda?.region}, {ticket.tienda?.pais}</p>
        </div>
        <div className="bg-gray-100 p-4 rounded-lg shadow-md">
          <h2 className="text-2xl font-semibold mb-4">Marca</h2>
          <p><strong>Nombre:</strong> {ticket.marca?.nombre}</p>
        </div>
        <div className="bg-gray-100 p-4 rounded-lg shadow-md">
          <h2 className="text-2xl font-semibold mb-4">Detalles del Ticket</h2>
          <p><strong>Fecha de Creación:</strong> {new Date(ticket.created_at).toLocaleDateString()}</p>
          <p><strong>Tipo de Fidelización:</strong> {ticket.tipoFidelizacion}</p>
          {ticket.tipoFidelizacion === 'PUNTOS' ? (
            <div className="mt-4">
              <h3 className="text-xl font-semibold mb-2">Progreso de Puntos</h3>
              <div className="flex items-center">
                {renderStars(ticket.puntos, ticket.marca.puntosTicket)}
                {pointsRemaining > 0 ? (
                  <span className="ml-2">{pointsRemaining} puntos para completar</span>
                ) : (
                  <div className="text-green-500 text-lg font-bold mt-4">
                    ¡Tarjeta lista para canjear, ve a buscar tu premio!
                  </div>
                )}
              </div>
            </div>
          ) : (
            <div className="mt-4">
              <p><strong>Acumulado:</strong> {ticket.puntos}</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default TicketView;
