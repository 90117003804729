// src/App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Home from './pages/common/Home';
import Login from './pages/auth/Login';
import Register from './pages/auth/Register';
import Dashboard from './pages/common/Dashboard';
import ProtectedRoute from './components/routes/ProtectedRoute';
import Header from './components/common/Header';
import Footer from './components/common/Footer';
import PublicRoute from './components/routes/PublicRoute';
import TermsAndConditions from './pages/common/TermsAndConditions';
import CookiePolicyPage from './pages/common/CookiePolicyPage';
import PaymentResult from './pages/payment/PaymentResult';
import Settings from './pages/cliente/Settings';
import ResetPassword from './pages/auth/ResetPassword';
import ChangePassword from './pages/auth/ChangePassword';
import EmailVerification from './pages/auth/EmailVerification';
import SubscriptionPage from './pages/payment/SubscriptionPage';
import ProtectedLayout from './components/routes/ProtectedLayout';
import MarcasPage from './pages/tienda/MarcasPage';
import TiendasPage from './pages/tienda/TiendasPage';
import ClientesList from './pages/cliente/ClientesList';
import CrearCliente from './pages/cliente/CrearCliente';
import HistoryPage from './pages/cliente/HistoryPage';
import TrabajadorLogin from './pages/auth/TrabajadorLogin';
import TrabajadorDashboard from './pages/trabajador/TrabajadorDashboard';
import TrabajadorRegister from './pages/auth/TrabajadorRegister';
import ProtectedTrabajadorRoute from './components/routes/ProtectedTrabajadorRoute';

//sureys
import DashboardSurveys from './pages/feedback/DashboardSurveys';
import SurveyCreator from './pages/feedback/SurveyCreator';
import SurveyResults from './pages/feedback/SurveyResults';
import SurveyPage from './pages/feedback/SurveyPage';
import CookieConsent from './components/common/CookieConsent';
import TicketView from './pages/cliente/TicketView';
import useAuthStore from './store/authStore';
import HeaderTrabajadores from './components/common/HeaderTrabajadores';

// StatisticsPage
import StatisticsDashboard from './pages/statistics/StatisticsDashboard';
import StatisticsPage from './pages/statistics/StatisticsPage';
import StoreStatisticsPage from './pages/statistics/StoreStatisticsPage';
import CustomerStatisticsPage from './pages/statistics/CustomerStatisticsPage';

function App() {

  const { trabajador } = useAuthStore();

  return (
    <Router>
      {trabajador ? <HeaderTrabajadores /> : <Header />}      <main>
        <Routes>
          {/* Envoltura para rutas publicas */}
          <Route element={<PublicRoute />}>
            <Route path="/" element={<Home />} />
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/trabajador/login" element={<TrabajadorLogin />} />
            <Route path="/trabajador/register" element={<TrabajadorRegister />} />
            <Route path="/reset-password" element={<ResetPassword />} />
            <Route path="/change-password" element={<ChangePassword />} />
            <Route path="/verify" element={<EmailVerification />} />
            <Route path="/tickets/:ticketUrl" element={<TicketView />} />
          </Route>

          {/* Envoltura para rutas protegidas */}
          <Route element={<ProtectedRoute />}>
            <Route element={<ProtectedLayout />}>
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/settings" element={<Settings />} />
              <Route path="/subscribe" element={<SubscriptionPage />} />
              <Route path="/marcas" element={<MarcasPage />} />
              <Route path="/tiendas" element={<TiendasPage />} />
              <Route path="/tiendas/:tiendaId/clientes" element={<ClientesList />} />
              <Route path="/tiendas/:tiendaId/clientes/create" element={<CrearCliente />} />
              <Route path="/history/:clienteId" element={<HistoryPage />} />

              <Route path="/dashboard-surveys" element={<DashboardSurveys />} />
              <Route path="/create-survey" element={<SurveyCreator />} />
              <Route path="/survey-results/:surveyId" element={<SurveyResults />} />

              {/* Statistics Routes */}
              <Route path="/statistics/*" element={<StatisticsDashboard />}>
                <Route path="marca/:marcaId?" element={<StatisticsPage />} />
                <Route path="tienda/:tiendaId?" element={<StoreStatisticsPage />} />
                <Route path="cliente/:clienteId?" element={<CustomerStatisticsPage />} />
                <Route path="" element={<Navigate replace to="marca" />} />
              </Route>
            </Route>
          </Route>

          {/* Envoltura para rutas protegidas del trabajador */}
          <Route element={<ProtectedTrabajadorRoute />}>
            <Route path="/trabajador/:trabajadorId/dashboard" element={<TrabajadorDashboard />} />
            <Route path="/trabajador/:trabajadorId/tiendas/:tiendaId/clientes" element={<ClientesList />} />
            <Route path="/trabajador/:trabajadorId/tiendas/:tiendaId/clientes/create" element={<CrearCliente />} />
          </Route>

          <Route path="/payment-result" element={<PaymentResult />} />
          <Route path="/surveys/:surveyId" element={<SurveyPage />} />
          <Route path="/terminos" element={<TermsAndConditions />} />
          <Route path="/politica-de-cookies" element={<CookiePolicyPage />} />
          <Route path="*" element={<Navigate replace to="/" />} />
        </Routes>
        <CookieConsent />
      </main>
      <Footer />
    </Router>
  );
}

export default App;
