import React, { useState, useEffect } from 'react';

const AccumulatePointsModal = ({ montoConsumido, setMontoConsumido, puntosMaximos, puntosAcumulados, onClose, onAccumulate, tipoFidelizacion }) => {
  const [selectedPoints, setSelectedPoints] = useState(1);
  const [puntosDisponibles, setPuntosDisponibles] = useState([]);

  useEffect(() => {
    if (tipoFidelizacion === 'PUNTOS') {
      const maxPuntosDisponibles = puntosMaximos - puntosAcumulados;
      const opciones = Array.from({ length: maxPuntosDisponibles }, (_, i) => i + 1);
      setPuntosDisponibles(opciones);
    }
  }, [puntosMaximos, puntosAcumulados, tipoFidelizacion]);

  const handleMontoChange = (e) => {
    let value = e.target.value.replace(',', '.'); // Reemplazar coma por punto
    setMontoConsumido(value);
  };

  const handlePointsChange = (e) => {
    setSelectedPoints(Number(e.target.value));
  };

  const handleAccumulate = () => {
    onAccumulate(selectedPoints);
  };

  return (
    <div className="fixed z-10 inset-0 overflow-y-auto">
      <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div className="fixed inset-0 transition-opacity" aria-hidden="true">
          <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>
        <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
        <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
          <h2 className="text-2xl font-bold mb-4">Acumular Puntos</h2>
          <div>
            <label className="block mb-2">Monto Consumido</label>
            <input
              type="text"
              placeholder="Monto Consumido"
              value={montoConsumido}
              onChange={handleMontoChange}
              className="mb-4 p-2 border border-gray-300 rounded w-full"
            />
          </div>
          <div>
            <label className="block mb-2">Puntos a Acumular</label>
            {tipoFidelizacion === 'PUNTOS' ? (
              <select
                value={selectedPoints}
                onChange={handlePointsChange}
                className="mb-4 p-2 border border-gray-300 rounded w-full"
              >
                {puntosDisponibles.map((punto, index) => (
                  <option key={index} value={punto}>{punto} puntos</option>
                ))}
              </select>
            ) : (
              <input
                type="number"
                value={selectedPoints}
                onChange={handlePointsChange}
                className="mb-4 p-2 border border-gray-300 rounded w-full"
              />
            )}
          </div>
          <div className="flex justify-end">
            <button
              className="bg-gray-500 text-white px-4 py-2 rounded hover:bg-gray-700 mr-2"
              onClick={onClose}
            >
              Cancelar
            </button>
            <button
              className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-700"
              onClick={handleAccumulate}
            >
              Acumular
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccumulatePointsModal;
