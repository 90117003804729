import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation, Outlet } from 'react-router-dom';
import { get } from '../../helpers/apiHelper';
import Swal from 'sweetalert2';

const StatisticsDashboard = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState('marca');
  const [marcas, setMarcas] = useState([]);
  const [selectedMarca, setSelectedMarca] = useState(null);
  const [selectedTiendaId, setSelectedTiendaId] = useState('');
  const [selectedClienteId, setSelectedClienteId] = useState('');

  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        const response = await get('marcas');
        setMarcas(response);

        if (response.length > 0) {
          const firstMarca = response[0];
          setSelectedMarca(firstMarca);

          // Extraer el ID de la primera tienda
          if (firstMarca.tiendas.length > 0) {
            setSelectedTiendaId(firstMarca.tiendas[0]);
          }

          // Extraer el ID del primer cliente
          if (firstMarca.clientes.length > 0) {
            setSelectedClienteId(firstMarca.clientes[0]);
          }

          // Navegar automáticamente a la pestaña de marca si la ruta actual no es específica
          if (location.pathname === '/statistics' || location.pathname === '/statistics/') {
            navigate(`/statistics/marca/${firstMarca._id}`);
          }
        }
      } catch (error) {
        console.error('Error fetching data:', error);
        Swal.fire({
          title: 'Error',
          text: 'Error al obtener datos iniciales. ' + error.message,
          icon: 'error',
          confirmButtonText: 'Aceptar'
        });
      }
    };

    fetchInitialData();
  }, [location.pathname, navigate]);

  const handleTabChange = (tab) => {
    setActiveTab(tab);
    switch (tab) {
      case 'marca':
        navigate(`/statistics/marca/${selectedMarca?._id}`);
        break;
      case 'tienda':
        if (selectedTiendaId) {
          navigate(`/statistics/tienda/${selectedTiendaId}`);
        } else {
          Swal.fire({
            title: 'Error',
            text: 'No hay tiendas disponibles para esta marca.',
            icon: 'error',
            confirmButtonText: 'Aceptar'
          });
        }
        break;
      case 'cliente':
        if (selectedClienteId) {
          navigate(`/statistics/cliente/${selectedClienteId}`);
        } else {
          Swal.fire({
            title: 'Error',
            text: 'No hay clientes disponibles para esta marca.',
            icon: 'error',
            confirmButtonText: 'Aceptar'
          });
        }
        break;
      default:
        navigate(`/statistics/marca/${selectedMarca?._id}`);
    }
  };

  if (!selectedMarca) {
    return <div className="p-6 md:ml-64">Cargando datos...</div>;
  }

  return (
    <div className="min-h-screen bg-gray-100 p-6 md:ml-64">
      <div className="container mx-auto">
        <h1 className="text-4xl font-bold mb-6 text-center text-gray-800">Estadísticas</h1>

        <div className="mb-6 flex justify-center space-x-4">
          <button
            onClick={() => handleTabChange('marca')}
            className={`px-4 py-2 rounded-md ${activeTab === 'marca' ? 'bg-blue-500 text-white' : 'bg-gray-200 text-gray-800'}`}
          >
            Marca
          </button>
          <button
            onClick={() => handleTabChange('tienda')}
            className={`px-4 py-2 rounded-md ${activeTab === 'tienda' ? 'bg-blue-500 text-white' : 'bg-gray-200 text-gray-800'}`}
          >
            Tienda
          </button>
          <button
            onClick={() => handleTabChange('cliente')}
            className={`px-4 py-2 rounded-md ${activeTab === 'cliente' ? 'bg-blue-500 text-white' : 'bg-gray-200 text-gray-800'}`}
          >
            Cliente
          </button>
        </div>

        <div className="mt-6">
          <Outlet context={{ selectedMarca, selectedTiendaId, selectedClienteId }} />
        </div>
      </div>
    </div>
  );
};

export default StatisticsDashboard;
