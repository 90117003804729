import React, { useState } from 'react';
import Swal from 'sweetalert2';
import { post, del } from '../../helpers/apiHelper';  // Asume que esta es la ubicación correcta
import { useNavigate } from 'react-router-dom';
import useAuthStore from '../../store/authStore';

function Settings() {
    const [oldPassword, setPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmNewPassword, setConfirmNewPassword] = useState('');
    const navigate = useNavigate();  // Obtén la instancia de useNavigate para realizar redirecciones
    const { logout } = useAuthStore();
    
    const handleChangePassword = async (e) => {
      e.preventDefault();
      if (!oldPassword || !newPassword || !confirmNewPassword) {
        Swal.fire('Error', 'Todos los campos de contraseña son obligatorios.', 'error');
        return;
      }
  
      if (newPassword !== confirmNewPassword) {
        Swal.fire('Error', 'Las contraseñas nuevas no coinciden.', 'error');
        return;
      }
  
      Swal.fire({
        title: '¿Estás seguro?',
        text: "¿Deseas cambiar tu contraseña?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sí, cambiarla',
        cancelButtonText: 'Cancelar'
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            await post('auth/change-password', { oldPassword, newPassword });
            Swal.fire('¡Actualizado!', 'Tu contraseña ha sido cambiada.', 'success').then(() => {
              navigate('/dashboard');  // Redirige al dashboard después de cambiar la contraseña
            });
          } catch (error) {
            Swal.fire('Error', error.message, 'error');
          }
        }
      });
    };
  
    const handleDeleteAccount = async () => {
      Swal.fire({
        title: '¿Estás seguro?',
        text: "¿Realmente deseas eliminar tu cuenta? Este proceso no puede deshacerse.",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#d33',
        cancelButtonColor: '#3085d6',
        confirmButtonText: 'Sí, eliminarla',
        cancelButtonText: 'Cancelar'
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            await del('auth/delete-account');
            logout(); 
            Swal.fire('¡Eliminado!', 'Tu cuenta ha sido eliminada.', 'success').then(() => {
              navigate('/');  // Redirige a la página principal después de eliminar la cuenta
            });
          } catch (error) {
            Swal.fire('Error', error.message, 'error');
          }
        }
      });
    };

  return (
    <div className="min-h-screen flex flex-col justify-center items-center bg-gray-100 p-4 md:ml-64">
      <h1 className="text-2xl font-semibold mb-6">Configuración</h1>
      <form onSubmit={handleChangePassword} className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2">
            Contraseña Actual:
          </label>
          <input type="password" value={oldPassword} onChange={(e) => setPassword(e.target.value)} className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2">
            Nueva Contraseña:
          </label>
          <input type="password" value={newPassword} onChange={(e) => setNewPassword(e.target.value)} className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline" />
        </div>
        <div className="mb-6">
          <label className="block text-gray-700 text-sm font-bold mb-2">
            Confirmar Nueva Contraseña:
          </label>
          <input type="password" value={confirmNewPassword} onChange={(e) => setConfirmNewPassword(e.target.value)} className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" />
        </div>
        <div className="flex items-center justify-between">
          <button type="submit" className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">
            Cambiar Contraseña
          </button>
        </div>
      </form>
      <button onClick={handleDeleteAccount} className="mt-4 bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">
        Eliminar Cuenta
      </button>
    </div>
  );
}

export default Settings;
