import React from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import useAuthStore from '../../store/authStore';

function HeaderTrabajadores() {
  const { logout } = useAuthStore();
  const navigate = useNavigate();

  const handleLogout = () => {
    logout();
    navigate('/');
  };

  return (
    <header className="bg-gradient-to-r from-indigo-500 to-blue-400 text-white p-4 flex justify-between items-center">
      <h1 className="text-2xl font-bold">
        <Link to="/" className="hover:underline">PetitPrime</Link>
      </h1>
      <button onClick={handleLogout} className="p-2 bg-red-600 hover:bg-red-800 rounded-full transition-colors duration-300">
        <FontAwesomeIcon icon={faSignOutAlt} />
        Salir
      </button>
    </header>
  );
}

export default HeaderTrabajadores;
