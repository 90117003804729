import React, { useState } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { post } from '../../helpers/apiHelper';
import Swal from 'sweetalert2';

function ChangePassword() {
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [searchParams] = useSearchParams();
    const token = searchParams.get('token');
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (password !== confirmPassword) {
            Swal.fire('Passwords do not match', '', 'error');
            return;
        }

        try {
            await post('auth/reset-password', { token, password });
            Swal.fire('Password changed successfully', '', 'success');
            navigate('/login');
        } catch (error) {
            Swal.fire('Error changing password', error.response?.data?.message || 'Please try again later.', 'error');
        }
    };

    return (
        <div className="container mx-auto px-4">
            <h1 className="text-xl font-bold text-center my-4">Change Your Password</h1>
            <form onSubmit={handleSubmit} className="max-w-md mx-auto">
                <label htmlFor="password" className="block text-sm font-medium text-gray-700">New Password</label>
                <input
                    type="password"
                    id="password"
                    required
                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                />
                <label htmlFor="confirmPassword" className="block text-sm font-medium text-gray-700 mt-4">Confirm New Password</label>
                <input
                    type="password"
                    id="confirmPassword"
                    required
                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                />
                <button type="submit" className="mt-6 w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                    Change Password
                </button>
            </form>
        </div>
    );
}

export default ChangePassword;
