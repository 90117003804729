import React, { useState, useEffect } from 'react';
import useAuthStore from '../../store/authStore';
import { get, post, del } from '../../helpers/apiHelper';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import TrabajadorModal from '../../components/modals/TrabajadorModal';

function TiendasPage() {
  const { user } = useAuthStore();
  const [tiendas, setTiendas] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showTrabajadorModal, setShowTrabajadorModal] = useState(false);
  const [newTienda, setNewTienda] = useState({
    via: '',
    calle: '',
    numero: '',
    poblacion: '',
    pais: 'España',
    region: '',
    nombre: '',
    descripcion: '',
    marca: '',
  });
  const [marcas, setMarcas] = useState([]);
  const [selectedTiendaId, setSelectedTiendaId] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    if (user) {
      fetchTiendas();
      fetchMarcas();
    }
  }, [user]);

  const fetchTiendas = async () => {
    try {
      const response = await get('tiendas');
      setTiendas(response);
    } catch (error) {
      console.error('Error fetching tiendas:', error);
    }
  };

  const fetchMarcas = async () => {
    try {
      const response = await get('marcas');
      setMarcas(response);
      if (response.length > 0) {
        setNewTienda((prev) => ({ ...prev, marca: response[0]._id }));
      }
    } catch (error) {
      console.error('Error fetching marcas:', error);
    }
  };

  const handleShowModal = () => {
    if (marcas.length === 0) {
      Swal.fire({
        title: 'Advertencia',
        text: 'Necesitas crear una marca antes de poder crear tiendas.',
        icon: 'warning',
        confirmButtonText: 'Aceptar'
      });
    } else {
      setShowModal(true);
    }
  };

  const handleCloseModal = () => setShowModal(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewTienda((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await post('tiendas', { ...newTienda, usuario: user.userId });
      fetchTiendas();
      handleCloseModal();
      Swal.fire({
        title: 'Éxito',
        text: 'Tienda creada correctamente',
        icon: 'success',
        confirmButtonText: 'Aceptar'
      });
    } catch (error) {
      Swal.fire({
        title: 'Error',
        text: error.message,
        icon: 'error',
        confirmButtonText: 'Aceptar'
      });
    }
  };

  const handleDelete = async (id) => {
    const result = await Swal.fire({
      title: '¿Estás seguro?',
      text: 'No podrás revertir esto!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sí, eliminar!',
      cancelButtonText: 'Cancelar'
    });

    if (result.isConfirmed) {
      try {
        await del(`tiendas/${id}`);
        fetchTiendas();
        Swal.fire(
          'Eliminada!',
          'La tienda ha sido eliminada.',
          'success'
        );
      } catch (error) {
        Swal.fire({
          title: 'Error',
          text: error.message,
          icon: 'error',
          confirmButtonText: 'Aceptar'
        });
      }
    }
  };

  const handleViewClientes = (tiendaId) => {
    navigate(`/tiendas/${tiendaId}/clientes`);
  };

  const handleCreateCliente = (tiendaId) => {
    navigate(`/tiendas/${tiendaId}/clientes/create`);
  };

  const handleShowTrabajadorModal = (tiendaId) => {
    setSelectedTiendaId(tiendaId);
    setShowTrabajadorModal(true);
  };

  return (
    <div className="bg-white p-4 md:p-6 rounded-lg shadow-md md:ml-64">
      <h1 className="text-2xl md:text-4xl font-bold mb-4">Tiendas</h1>
      <p className="text-md md:text-xl mb-4">
        Aquí puedes gestionar tus tiendas. Las tiendas están asociadas a marcas y
        comparten un modelo de fidelización.
      </p>
      <button
        className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-700"
        onClick={handleShowModal}
      >
        Crear Tienda
      </button>
      <div className="mt-6 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        {tiendas.map((tienda) => (
          <div key={tienda._id} className="border p-4 rounded mb-4">
            <h2 className="text-xl md:text-2xl font-bold">{tienda.nombre}</h2>
            <p>{tienda.descripcion}</p>
            <p><strong>Dirección:</strong> {tienda.via} {tienda.calle}, {tienda.numero}, {tienda.poblacion}, {tienda.pais}, {tienda.region}</p>
            <div className="flex flex-wrap space-x-4 mt-2">
              <button
                className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-700 my-2"
                onClick={() => handleViewClientes(tienda._id)}
              >
                Ver Clientes
              </button>
              <button
                className="bg-yellow-500 text-white px-4 py-2 rounded hover:bg-yellow-700 my-2"
                onClick={() => handleCreateCliente(tienda._id)}
              >
                Crear Cliente
              </button>
              <button
                className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-700 my-2"
                onClick={() => handleShowTrabajadorModal(tienda._id)}
              >
                Añadir Trabajador
              </button>
              <button
                className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-700 my-2"
                onClick={() => handleDelete(tienda._id)}
              >
                Eliminar Tienda
              </button>
            </div>
          </div>
        ))}
      </div>

      {showModal && (
        <div className="fixed z-10 inset-0 overflow-y-auto">
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div className="fixed inset-0 transition-opacity" aria-hidden="true">
              <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
            </div>
            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
              <h2 className="text-2xl font-bold mb-4">Crear Tienda</h2>
              <form onSubmit={handleSubmit}>
                <div className="mb-4">
                  <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="via">
                    Vía
                  </label>
                  <input
                    type="text"
                    name="via"
                    value={newTienda.via}
                    onChange={handleChange}
                    required
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  />
                </div>
                <div className="mb-4">
                  <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="calle">
                    Nombre calle
                  </label>
                  <input
                    type="text"
                    name="calle"
                    value={newTienda.calle}
                    onChange={handleChange}
                    required
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  />
                </div>
                <div className="mb-4">
                  <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="numero">
                    Número
                  </label>
                  <input
                    type="text"
                    name="numero"
                    value={newTienda.numero}
                    onChange={handleChange}
                    required
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  />
                </div>
                <div className="mb-4">
                  <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="pais">
                    País
                  </label>
                  <select
                    name="pais"
                    value={newTienda.pais}
                    onChange={handleChange}
                    required
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  >
                    <option value="España">España</option>
                    <option value="Chile">Chile</option>
                    <option value="Peru">Peru</option>
                  </select>
                </div>
                <div className="mb-4">
                  <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="region">
                    Región
                  </label>
                  <input
                    type="text"
                    name="region"
                    value={newTienda.region}
                    onChange={handleChange}
                    required
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  />
                </div>
                <div className="mb-4">
                  <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="poblacion">
                    Ciudad
                  </label>
                  <input
                    type="text"
                    name="poblacion"
                    value={newTienda.poblacion}
                    onChange={handleChange}
                    required
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  />
                </div>
                <div className="mb-4">
                  <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="nombre">
                    Nombre de la Tienda
                  </label>
                  <input
                    type="text"
                    name="nombre"
                    value={newTienda.nombre}
                    onChange={handleChange}
                    required
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  />
                </div>
                <div className="mb-4">
                  <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="descripcion">
                    Descripción
                  </label>
                  <textarea
                    name="descripcion"
                    value={newTienda.descripcion}
                    onChange={handleChange}
                    required
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  />
                </div>
                <div className="mb-4">
                  <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="marca">
                    Marca
                  </label>
                  <select
                    name="marca"
                    value={newTienda.marca}
                    onChange={handleChange}
                    required
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  >
                    {marcas.map((marca) => (
                      <option key={marca._id} value={marca._id}>{marca.nombre}</option>
                    ))}
                  </select>
                </div>
                <div className="flex justify-end">
                  <button
                    type="button"
                    onClick={handleCloseModal}
                    className="bg-gray-500 text-white px-4 py-2 rounded hover:bg-gray-700 mr-2"
                  >
                    Cancelar
                  </button>
                  <button
                    type="submit"
                    className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-700"
                  >
                    Crear
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}

      <TrabajadorModal
        show={showTrabajadorModal}
        onClose={() => setShowTrabajadorModal(false)}
        tiendaId={selectedTiendaId}
      />
    </div>
  );
}

export default TiendasPage;
