import React, { useState, useEffect } from 'react';
import { get } from '../../helpers/apiHelper';
import Swal from 'sweetalert2';
import { FaUserAlt, FaStore } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';

function Dashboard() {
  const [marcas, setMarcas] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    fetchMarcas();
  }, []);

  const fetchMarcas = async () => {
    try {
      const response = await get('marcas');
      const marcasConEstadisticas = await Promise.all(
        response.map(async (marca, index) => {
          const stats = await get(`statistics/marca/${marca._id}`);
          return {
            ...marca,
            ...stats,
            displayName: `Tarjeta ${index + 1}`
          };
        })
      );
      setMarcas(marcasConEstadisticas);
    } catch (error) {
      console.error('Error fetching marcas:', error);
      Swal.fire({
        title: 'Error',
        text: error.message,
        icon: 'error',
        confirmButtonText: 'Aceptar'
      });
    }
  };

  const handleNavigate = (path) => {
    console.log(path);
    navigate(path);
  };

  return (
    <div className="min-h-screen bg-gray-100 p-6 md:ml-64">
      <div className="container mx-auto">
        <h1 className="text-4xl font-bold mb-6 text-center text-gray-800">Tu Dashboard</h1>
        <p className="text-xl mb-6 text-center">¡Bienvenido! Aquí puedes gestionar tus marcas y ver estadísticas.</p>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {marcas.map((marca) => (
            <div key={marca._id} className="bg-white p-6 rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300">
              <h2 className="text-2xl font-bold mb-4 text-gray-700">{marca.nombre}</h2>
              <div className="flex items-center mb-4">
                <FaUserAlt className="text-blue-500 text-2xl" />
                <div className="ml-4">
                  <span className="text-lg font-semibold text-gray-700">Clientes:</span>
                  <span className="text-2xl ml-2 text-gray-900">{marca.totalClientes}</span>
                </div>
              </div>
              <div className="flex items-center mb-4">
                <FaStore className="text-green-500 text-2xl" />
                <div className="ml-4">
                  <span className="text-lg font-semibold text-gray-700">Tiendas:</span>
                  <span className="text-2xl ml-2 text-gray-900">{marca.totalTiendas}</span>
                </div>
              </div>
              {/* <div className="flex items-center">
                <FaChartLine className="text-red-500 text-2xl" />
                <div className="ml-4">
                  <span className="text-lg font-semibold text-gray-700">Estadísticas:</span>
                  <span className="text-2xl ml-2 text-gray-900">{marca.totalEstadisticas}</span>
                </div>
              </div> */}
            </div>
          ))}
        </div>
        <div className="mt-10">
          <h2 className="text-3xl font-bold mb-6 text-center text-gray-800">Módulos Disponibles</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            <div className="bg-white p-6 rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300">
              <h3 className="text-2xl font-bold mb-4 text-gray-700">Gestión de Tiendas</h3>
              <p className="text-gray-600 mb-4">Administra todas tus tiendas de manera eficiente.</p>
              <button
                onClick={() => handleNavigate('/tiendas')}
                className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-700"
              >
                Ver Tiendas
              </button>
            </div>
            <div className="bg-white p-6 rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300">
              <h3 className="text-2xl font-bold mb-4 text-gray-700">Gestión de Marcas</h3>
              <p className="text-gray-600 mb-4">Administra todas tus marcas fácilmente.</p>
              <button
                onClick={() => handleNavigate('/marcas')}
                className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-700"
              >
                Ver Marcas
              </button>
            </div>
            <div className="bg-white p-6 rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300">
              <h3 className="text-2xl font-bold mb-4 text-gray-700">Feedback</h3>
              <p className="text-gray-600 mb-4">Feedback de los clientes.</p>
              <button
                onClick={() => handleNavigate('/dashboard-surveys')}
                className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-700"
              >
                Ver Feedback
              </button>
            </div>
            <div className="bg-white p-6 rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300">
              <h3 className="text-2xl font-bold mb-4 text-gray-700">Estadística</h3>
              <p className="text-gray-600 mb-4">Información procesada.</p>
              <button
                onClick={() => handleNavigate('/statistics')}
                className="bg-yellow-500 text-white px-4 py-2 rounded hover:bg-yellow-700"
              >
                Ver Estadisticas
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
