import React, { useState, useEffect } from 'react';
import { differenceInCalendarDays } from 'date-fns';
import useAuthStore from '../../store/authStore';
import { useNavigate, Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog, faSignOutAlt, faBars } from '@fortawesome/free-solid-svg-icons';
import { get } from '../../helpers/apiHelper';
import Sidebar from './Sidebar';

function Header() {
  const { user, token, logout } = useAuthStore();
  const navigate = useNavigate();
  const [trialDaysLeft, setTrialDaysLeft] = useState(null);
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  useEffect(() => {
    if (user) {
      const fetchUserData = async () => {
        try {
          const response = await get(`users/${user.userId}`);
          if (response) {
            const userData = response;
            setIsSubscribed(userData.hasPaid);
            if (!userData.hasPaid) {
              const trialEndDate = new Date(userData.trialStarted);
              trialEndDate.setDate(trialEndDate.getDate() + 30); 
              const daysLeft = differenceInCalendarDays(trialEndDate, new Date());
              setTrialDaysLeft(daysLeft);
            }
          } else {
            console.error('User data not found in the response:', response);
          }
        } catch (error) {
          console.error('Failed to fetch user data:', error);
        }
      };
      fetchUserData();
    }
  }, [user]);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const handleLogout = () => {
    logout();
    navigate('/');
  };

  const handleNavigateToSubscribe = () => {
    navigate('/subscribe');
  };

  return (
    <header className="bg-gradient-to-r from-indigo-500 to-blue-400 text-white p-4 flex justify-between items-center relative">
      <div className="flex items-center space-x-4">
        {(user) && (
          <button onClick={toggleSidebar} className="text-xl md:hidden z-30">
            <FontAwesomeIcon icon={faBars} />
          </button>
        )}
        <h1 className="text-2xl font-bold cursor-pointer">
          <Link to="/" className="hover:underline">PetitPrime</Link>
        </h1>
      </div>
      {user ? (
        <>
          <div className="hidden md:flex items-center space-x-4">
            {user && !isSubscribed && trialDaysLeft !== null && (
              <div onClick={handleNavigateToSubscribe} className="text-sm lg:text-base cursor-pointer">
                {trialDaysLeft > 0 ? `Trial ends in ${trialDaysLeft} days` : 'Trial ended'}
              </div>
            )}
            {user && !isSubscribed && (
              <button
                onClick={handleNavigateToSubscribe}
                className="py-2 px-4 bg-yellow-500 hover:bg-yellow-700 rounded-full text-lg transition-colors duration-300"
              >
                Suscribete Ahora
              </button>
            )}
            <Link to="/settings" className="p-2 bg-blue-600 hover:bg-blue-800 rounded-full transition-colors duration-300">
              <FontAwesomeIcon icon={faCog} />
            </Link>
            <button onClick={handleLogout} className="p-2 bg-red-600 hover:bg-red-800 rounded-full transition-colors duration-300">
              <FontAwesomeIcon icon={faSignOutAlt} />
            </button>
          </div>
          <div className="md:hidden flex items-center space-x-4">
            {user && !isSubscribed && trialDaysLeft !== null && (
              <div onClick={handleNavigateToSubscribe} className="text-xs">
                {trialDaysLeft > 0 ? `Trial ends in ${trialDaysLeft} days` : 'Trial ended'}
              </div>
            )}
            <Link to="/settings" className="p-2 bg-blue-600 hover:bg-blue-800 rounded-full transition-colors duration-300">
              <FontAwesomeIcon icon={faCog} />
            </Link>
            <button onClick={handleLogout} className="p-2 bg-red-600 hover:bg-red-800 rounded-full transition-colors duration-300">
              <FontAwesomeIcon icon={faSignOutAlt} />
            </button>
          </div>
        </>
      ) : (
        <>
            <div className="hidden md:flex items-center space-x-4">
              <button onClick={() => navigate('/login')} className="py-2 px-4 bg-green-600 hover:bg-green-800 rounded-full text-lg transition-colors duration-300">
                Iniciar sesión
              </button>
            </div>
            <div className="md:hidden flex items-center space-x-4">
              <button onClick={() => navigate('/login')} className="py-2 px-4 bg-green-600 hover:bg-green-800 rounded-full text-lg transition-colors duration-300">
                Iniciar sesión
              </button>
            </div>
          </>
      )}
      {isSidebarOpen && user && (
        <Sidebar isOpen={isSidebarOpen} />
      )}
    </header>
  );
}

export default Header;
