import React, { useState, useEffect } from 'react';
import { get, post, patch } from '../../helpers/apiHelper';
import Swal from 'sweetalert2';

function ManagePremiosModal({ marca, onClose, onRefresh }) {
  const [premios, setPremios] = useState([]);
  const [newPremio, setNewPremio] = useState({
    nombre: '',
    descripcion: '',
    puntosNecesarios: 0
  });

  useEffect(() => {
    if (marca) {
      fetchPremios();
    }
  }, [marca]);

  const fetchPremios = async () => {
    try {
      const response = await get(`marcas/${marca._id}/premios`);
      setPremios(response);
    } catch (error) {
      console.error('Error fetching premios:', error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewPremio((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const premioData = {
        ...newPremio,
        puntosNecesarios: parseInt(newPremio.puntosNecesarios, 10),
        activo: true
      };
      await post(`marcas/${marca._id}/premios`, premioData);
      fetchPremios();
      setNewPremio({ nombre: '', descripcion: '', puntosNecesarios: 0 });
      Swal.fire({
        title: 'Éxito',
        text: 'Premio creado correctamente',
        icon: 'success',
        confirmButtonText: 'Aceptar'
      });
    } catch (error) {
      Swal.fire({
        title: 'Error',
        text: error.message,
        icon: 'error',
        confirmButtonText: 'Aceptar'
      });
    }
  };

  const handleDeactivate = async (id) => {
    const result = await Swal.fire({
      title: '¿Estás seguro?',
      text: "Esta acción no se puede deshacer",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sí, desactivar',
      cancelButtonText: 'Cancelar'
    });
    if (result.isConfirmed) {
      try {
        await patch(`marcas/${marca._id}/premios/${id}/deactivate`);
        fetchPremios();
        Swal.fire({
          title: 'Desactivado',
          text: 'El premio ha sido desactivado.',
          icon: 'success',
          confirmButtonText: 'Aceptar'
        });
      } catch (error) {
        Swal.fire({
          title: 'Error',
          text: error.message,
          icon: 'error',
          confirmButtonText: 'Aceptar'
        });
      }
    }
  };

  return (
    <div className="fixed z-10 inset-0 overflow-y-auto">
      <div className="flex items-center justify-center min-h-screen">
        <div className="fixed inset-0 transition-opacity" aria-hidden="true">
          <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>
        <div className="bg-white rounded-lg overflow-hidden shadow-xl transform transition-all max-w-lg w-full p-6">
          <div className="flex justify-between items-center pb-3">
            <h3 className="text-lg font-medium leading-6 text-gray-900">Gestionar Premios</h3>
            <button onClick={onClose} className="text-gray-400 hover:text-gray-600">
              <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
          </div>
          <form onSubmit={handleSubmit}>
            <div className="mb-4">
              <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="nombre">
                Nombre del Premio
              </label>
              <input 
                type="text"
                name="nombre"
                value={newPremio.nombre}
                onChange={handleChange}
                required
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              />
            </div>
            <div className="mb-4">
              <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="descripcion">
                Descripción
              </label>
              <input 
                type="text"
                name="descripcion"
                value={newPremio.descripcion}
                onChange={handleChange}
                required
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              />
            </div>
            <div className="mb-4">
              <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="puntosNecesarios">
                Puntos Necesarios
              </label>
              <input 
                type="number"
                name="puntosNecesarios"
                value={newPremio.puntosNecesarios}
                onChange={handleChange}
                min={1}
                required
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              />
            </div>
            <div className="flex justify-end">
              <button
                type="button"
                onClick={onClose}
                className="bg-gray-500 text-white px-4 py-2 rounded hover:bg-gray-700 mr-2"
              >
                Cancelar
              </button>
              <button 
                type="submit"
                className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-700"
              >
                Añadir Premio
              </button>
            </div>
          </form>
          <div className="mt-6">
            <h4 className="text-lg font-medium leading-6 text-gray-900 mb-3">Premios Disponibles</h4>
            {premios.length > 0 ? (
              premios.filter(premio => premio.activo).map((premio) => (
                <div key={premio._id} className="p-4 mb-4 border rounded flex justify-between items-center">
                  <div>
                    <h5 className="text-xl font-bold">{premio.nombre}</h5>
                    <p>{premio.descripcion}</p>
                    <p>{`Puntos Necesarios: ${premio.puntosNecesarios}`}</p>
                  </div>
                  {premio.activo && (
                    <button 
                      onClick={() => handleDeactivate(premio._id)}
                      className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-700"
                    >
                      Desactivar
                    </button>
                  )}
                </div>
              ))
            ) : (
              <p>No hay premios disponibles.</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ManagePremiosModal;
