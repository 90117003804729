import React, { useState } from 'react';
import { post } from '../../helpers/apiHelper';
import Swal from 'sweetalert2';

function ResetPassword() {
  const [email, setEmail] = useState('');

  const handleResetPassword = async (e) => {
    e.preventDefault();
    try {
      const data = await post('auth/forgot-password', { email });
      Swal.fire({
        icon: 'success',
        title: 'Correo Enviado',
        text: data.message,
        confirmButtonColor: '#10B981', // Tailwind green-500
      });
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: error.message || 'Algo salió mal',
        confirmButtonColor: '#EF4444', // Tailwind red-500
      });
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-100 px-6 py-12">
      <div className="max-w-md w-full space-y-8">
        <div>
          <h1 className="text-center text-3xl font-extrabold text-gray-900">Restablecer Contraseña</h1>
        </div>
        <form className="mt-8 space-y-6" onSubmit={handleResetPassword}>
          <input type="hidden" name="remember" value="true" />
          <div className="rounded-md shadow-sm -space-y-px">
            <div>
              <label htmlFor="email-address" className="sr-only">Correo electrónico</label>
              <input id="email-address" name="email" type="email" autoComplete="email" required
                className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                placeholder="Introduce tu correo electrónico" value={email} onChange={(e) => setEmail(e.target.value)} />
            </div>
          </div>
          <div>
            <button type="submit" className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
              Enviar enlace de restablecimiento
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default ResetPassword;
