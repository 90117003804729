// src/components/ProtectedTrabajadorRoute.jsx
import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import useAuthStore from '../../store/authStore';

const ProtectedTrabajadorRoute = () => {
  const { trabajador, isAuthenticated } = useAuthStore(state => ({
    trabajador: state.trabajador,
    isAuthenticated: state.isAuthenticated
  }));

  if (!isAuthenticated || !trabajador) {
    return <Navigate to="/trabajador/login" replace />;
  }

  return <Outlet />;
};

export default ProtectedTrabajadorRoute;
