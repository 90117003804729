// components/OpenTextResponses.jsx
import React from 'react';

const OpenTextResponses = ({ responses }) => {
  // Agrupar respuestas por pregunta
  const groupedResponses = responses.reduce((acc, response) => {
    acc[response.question] = acc[response.question] || [];
    acc[response.question].push(response.answer);
    return acc;
  }, {});

  return (
    <div className="bg-gray-100 p-5 rounded-lg my-5">
  <h2 className="text-xl font-semibold mb-4">Respuestas Abiertas</h2>
  {Object.entries(groupedResponses).map(([question, answers], index) => (
    <div key={index} className="mb-4">
      <p className="font-bold mb-1">{question}</p>
      {answers.map((answer, idx) => (
        <p key={idx} className="pl-5 text-justify">{answer}</p>
      ))}
    </div>
  ))}
</div>

  );
};

export default OpenTextResponses;
